import { FiHome, FiBookOpen, FiUsers, FiPieChart, FiKey } from "react-icons/fi";
import type { IconType } from 'react-icons';

export interface IApplicationSidebarLink {
    id: number;
    title: string;
    path?: string;
    icon?: IconType;
    badge?: string;
    children?: IApplicationSidebarLink[];
}
 

export const applicationSidebarLinks:IApplicationSidebarLink[] =  [
        {
            id: 1,
            title: 'Menus',
            icon: FiBookOpen,
            path: "/app/menus"
        },
        {
            id: 2,
            title: 'Restaurantes',
            path: '/app/restaurants',
            icon: FiHome,
            badge: "Premium"
        },
        {
            id: 3,
            title: 'Personal',
            path: '/app/staff',
            icon: FiUsers,
            badge: "Premium"
        },
        {
            id: 4,
            title: 'Dashboard',
            path: '/app/dashboard',
            icon: FiPieChart
        }
        

    ];

    export const applicationSidebarLinksAdmin = [
        {
            id: 5,
            title: 'Admin',
            path: '/admin',
            icon: FiKey,
            children:[
                {
                    id: 1,
                    title: 'Early Adopters invitation',
                    path: '/app/admin/early-adopters-invite'
                },
                {
                    id: 2,
                    title: 'User Management',
                    path: '/app/admin/users'
                }
            ]
        }
    ];

