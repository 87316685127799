"use client";
import React from "react";
import { FiChevronRight, FiMenu } from "react-icons/fi";
import { usePathname } from "next/navigation";
import {
  applicationSidebarLinks,
  IApplicationSidebarLink,
} from "@/config/ApplicationSidebar";
export default function Breadcrumb() {
  const pathname = usePathname();
  const pathList = findPathName(pathname, applicationSidebarLinks) || [];
  return (
    <div className="sticky top-0 inset-x-0 z-20 bg-light border-y px-4 sm:px-6 md:px-8 lg:hidden dark:bg-dark dark:border-neutral-700">
      <div className="flex justify-between items-center py-2">
        {/* <!-- Breadcrumb --> */}
        <ol className="ms-3 flex items-center whitespace-nowrap">
          {pathList.map((path: string, index: number) => {
            return (
              <li
                key={index}
                className={`text-sm text-dark dark:text-neutral-400  ${
                  index === pathList.length - 1
                    ? "font-semibold truncate"
                    : "font-normal flex items-center"
                }`}
              >
                {path}
                {index !== pathList.length - 1 && <FiChevronRight />}
              </li>
            );
          })}
        </ol>
        {/* <!-- End Breadcrumb --> */}

        {/* <!-- Sidebar --> */}
        <button
          type="button"
          className="py-2 px-3 flex justify-center items-center gap-x-1.5 text-xs rounded-lg border border-gray-200 text-gray-500 hover:text-gray-600 dark:border-neutral-700 dark:text-neutral-400 dark:hover:text-neutral-200"
          data-hs-overlay="#application-sidebar"
          aria-controls="application-sidebar"
          aria-label="Sidebar"
        >
          <FiMenu />
          <span className="sr-only">Sidebar</span>
        </button>
        {/* <!-- End Sidebar --> */}
      </div>
    </div>
  );
}

function findPathName(
  pathName: string,
  links: IApplicationSidebarLink[]
): string[] | null {
  for (let link of links) {
    if (link.path === pathName) {
      return [link.title];
    }
    if (link.children) {
      let result: string[] | null = findPathName(pathName, link.children);
      if (result) {
        return [link.title, ...result];
      }
    }
  }
  return null;
}
