"use client";
import { useContext, useEffect, useState } from "react";
import { FiX } from "react-icons/fi";
import { AuthContext } from "@/app/providers";
import feedbackInstance from "@/models/Feedback";
import {
  EmailRecipient,
  MessageTemplate,
  sendTemplatedEmail,
} from "@/lib/mail";
import Alert from "./baseComponents/Alert";

export const FeedbackModalButton = () => {
  return (
    <button
      type="button"
      className="py-3 px-4 inline-flex items-center gap-x-2 text-sm font-medium rounded-lg border border-transparent bg-warning-600 text-white hover:bg-warning-700 focus:outline-none focus:bg-warning-700 disabled:opacity-50 disabled:pointer-events-none"
      aria-haspopup="dialog"
      aria-expanded="false"
      aria-controls="hs-feedback-from-user-modal"
      data-hs-overlay="#hs-feedback-from-user-modal"
    >
      ¿Qué opinas de WaiterMate?
    </button>
  );
};

export const FeedbackModalPopup = () => {
  const user = useContext(AuthContext);
  const [sending, setSending] = useState(false);
  const [formError, setFormError] = useState("");
  const [formData, setFormData] = useState({
    page: "",
    browser: "",
    url: "",
    referer: "",
    useragent: "",
    screen: "",
    viewport: "",
    language: "",
    colordepth: "",
    feedbackText: "",
    userName: "",
    userId: "",
    email: "",
  });

  useEffect(() => {
    const gatherFormData = () => {
      setFormData({
        page: document.title,
        browser: navigator.userAgent,
        url: window.location.href,
        referer: document.referrer,
        useragent: navigator.userAgent,
        screen: `${window.screen.width}x${window.screen.height}`,
        viewport: `${window.innerWidth}x${window.innerHeight}`,
        language: navigator.language,
        colordepth: `${window.screen.colorDepth}-bit`,
        feedbackText: "",
        userName: user.user?.displayName || user.user?.email || "",
        userId: user.user?.uid || "",
        email: user.user?.email || "",
      });
    };

    gatherFormData();
  }, [user]);

  const hanleFeedbackSubmit = async () => {
    if (!formData.feedbackText) {
      setFormError("Por favor, escribe tu opinión antes de enviar");
      return;
    }
    try {
      setSending(true);
      setFormError("");
      // save feedback to firestore, then close modal
      await feedbackInstance.create(formData);
      document
        .querySelector("#hs-feedback-from-user-modal")!
        .classList.add("hidden");
      const inAppFeedbackToAdminTemplateID = 6289364;
      const inAppFeedbackToUserTemplateID = 6289435;
      const adminRecipients: EmailRecipient[] =
        process.env.NEXT_PUBLIC_MAILJET_ADMIN_EMAILS?.split(" ").map(
          (email) => {
            return {
              Email: email,
              Name: "Admin",
            };
          }
        ) || [];
      const userRecipients: EmailRecipient[] = [
        { Email: formData.email, Name: formData.userName },
      ];
      const messages: MessageTemplate[] = [
        {
          // mail para los administradores
          From: {
            Email: process.env.NEXT_PUBLIC_MAILJET_EMAIL_FROM || "",
            Name: process.env.NEXT_PUBLIC_MAILJET_EMAIL_FROM_NAME || "",
          },
          To: adminRecipients,
          TemplateID: inAppFeedbackToAdminTemplateID,
          TemplateLanguage: true,
          Subject: "WaiterMate: Nuevo feedback",
          Variables: formData,
        },
        {
          // mail para el usuario
          From: {
            Email: process.env.NEXT_PUBLIC_MAILJET_EMAIL_FROM || "",
            Name: process.env.NEXT_PUBLIC_MAILJET_EMAIL_FROM_NAME || "",
          },
          To: userRecipients,
          TemplateID: inAppFeedbackToUserTemplateID,
          TemplateLanguage: true,
          Subject: "Gracias por tu feedback - WaiterMate",
          Variables: formData,
        },
      ];
      sendTemplatedEmail(messages);

      const backdrop = document.querySelector(
        "#hs-feedback-from-user-modal-backdrop"
      );
      if (backdrop) {
        backdrop.remove();
      }
    } catch (error) {
      console.error(error);
    } finally {
      setSending(false);
    }
  };

  return (
    <div
      id="hs-feedback-from-user-modal"
      className="hs-overlay hidden size-full fixed top-0 start-0 z-[80] overflow-x-hidden overflow-y-auto pointer-events-none"
      role="dialog"
      tabIndex={-1}
      aria-labelledby="hs-feedback-from-user-modal-label"
    >
      <div className="hs-overlay-open:mt-7 hs-overlay-open:opacity-100 hs-overlay-open:duration-500 mt-0 opacity-0 ease-out transition-all sm:max-w-lg sm:w-full m-3 sm:mx-auto h-[calc(100%-3.5rem)] min-h-[calc(100%-3.5rem)] flex items-center">
        <div className="w-full max-h-full overflow-hidden flex flex-col bg-white border shadow-sm rounded-xl pointer-events-auto dark:bg-neutral-800 dark:border-neutral-700 dark:shadow-neutral-700/70">
          <div className="flex justify-between items-center py-3 px-4 border-b dark:border-neutral-700">
            <h3
              id="hs-feedback-from-user-modal-label"
              className="font-bold text-gray-800 dark:text-white"
            >
              Compartenos tu opinión sobre WaiterMate
            </h3>
            <button
              type="button"
              className="size-8 inline-flex justify-center items-center gap-x-2 rounded-full border border-transparent bg-gray-100 text-gray-800 hover:bg-gray-200 focus:outline-none focus:bg-gray-200 disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-700 dark:hover:bg-neutral-600 dark:text-neutral-400 dark:focus:bg-neutral-600"
              aria-label="Close"
              data-hs-overlay="#hs-feedback-from-user-modal"
            >
              <span className="sr-only">Close</span>
              <FiX className="w-4 h-4" />
            </button>
          </div>
          <div className="p-4 overflow-y-auto">
            <div className="space-y-4">
              <form>
                <input type="hidden" name="page" value={formData.page} />
                <input type="hidden" name="browser" value={formData.browser} />
                <input type="hidden" name="url" value={formData.url} />
                <input type="hidden" name="referer" value={formData.referer} />
                <input
                  type="hidden"
                  name="useragent"
                  value={formData.useragent}
                />
                <input type="hidden" name="screen" value={formData.screen} />
                <input
                  type="hidden"
                  name="viewport"
                  value={formData.viewport}
                />
                <input
                  type="hidden"
                  name="language"
                  value={formData.language}
                />
                <input
                  type="hidden"
                  name="colordepth"
                  value={formData.colordepth}
                />
                <input
                  type="hidden"
                  name="userName"
                  value={formData.userName}
                />
                <input type="hidden" name="userId" value={formData.userId} />
                <input type="hidden" name="email" value={formData.email} />
                <textarea
                  name="feedbackText"
                  value={formData.feedbackText}
                  onChange={(e) =>
                    setFormData({ ...formData, feedbackText: e.target.value })
                  }
                  className="w-full h-32 p-2 border border-gray-200 rounded-lg focus:outline-none focus:border-primary-600 dark:border-neutral-700 dark:focus:border-primary-600 dark:bg-dark dark:text-light"
                  placeholder="Escribe aquí tu opinión sobre WaiterMate"
                  required
                ></textarea>
                {formError && (
                  <p className="text-sm text-danger-600">{formError}</p>
                )}
              </form>
            </div>
          </div>
          <div className="flex justify-end items-center gap-x-2 py-3 px-4 border-t dark:border-neutral-700">
            <button
              type="button"
              className="py-2 px-3 inline-flex items-center gap-x-2 text-sm font-medium rounded-lg border border-gray-200 bg-white text-gray-800 shadow-sm hover:bg-gray-50 focus:outline-none focus:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-800 dark:border-neutral-700 dark:text-white dark:hover:bg-neutral-700 dark:focus:bg-neutral-700"
              data-hs-overlay="#hs-feedback-from-user-modal"
            >
              Cerrar
            </button>
            <button
              type="button"
              className="py-2 px-3 inline-flex items-center gap-x-2 text-sm font-medium rounded-lg border border-transparent bg-primary-600 text-white hover:bg-primary-700 focus:outline-none focus:bg-primary-700 disabled:opacity-50 disabled:pointer-events-none"
              onClick={hanleFeedbackSubmit}
              disabled={sending}
            >
              {sending ? "Enviando..." : "Enviar comentarios"}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
