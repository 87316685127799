function HeaderButtons({ buttonProps }: { buttonProps: any }) {
  return (
    <button
      type="button"
      className="w-[2.375rem] h-[2.375rem] inline-flex justify-center items-center gap-x-2 text-sm font-semibold rounded-full border border-transparent text-gray-800 hover:bg-gray-100 disabled:opacity-50 disabled:pointer-events-none dark:text-white dark:hover:bg-neutral-700"
      data-hs-offcanvas="#hs-offcanvas-right"
    >
      {buttonProps.icon && <buttonProps.icon />}
      <span
        className="hs-tooltip-content hs-tooltip-shown:opacity-100 hs-tooltip-shown:visible opacity-0 transition-opacity inline-block absolute invisible z-10 py-1 px-2 bg-gray-900 text-xs font-medium text-white rounded shadow-sm dark:bg-neutral-700"
        role="tooltip"
      >
        {buttonProps.title}
      </span>
    </button>
  );
}
export default HeaderButtons;
