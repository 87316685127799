"use client";
import Image from "next/image";
import Link from "next/link";
import { AvatarLinks } from "@/config/Header";
import { signOut } from "firebase/auth";
import { auth } from "@/lib/firebase/client";
import { FiLogOut, FiUser } from "react-icons/fi";
import { useContext } from "react";
import { AuthContext } from "@/app/providers";

const storageBucket: string =
  process.env.NEXT_PUBLIC_FIREBASE_STORAGE_BUCKET ?? "";

export default function Avatar() {
  const { user, userClaims } = useContext(AuthContext);
  return (
    <div className="hs-dropdown relative inline-flex">
      <button
        id="hs-dropdown-with-header"
        type="button"
        className="relative overflow-hidden w-[2.375rem] h-[2.375rem] inline-flex justify-center items-center gap-x-2 text-sm font-semibold rounded-full border border-transparent text-gray-800 hover:bg-gray-100 disabled:opacity-50 disabled:pointer-events-none dark:text-white dark:hover:bg-neutral-700"
      >
        {user?.photoURL && (
          <Image
            fill
            className="object-cover rounded-full overflow-hidden ring-2 ring-white dark:ring-neutral-800 mb-4"
            src={
              user.photoURL ||
              `https://firebasestorage.googleapis.com/v0/b/${storageBucket}/o/generic%2Fprofile-pic.png?alt=media`
            }
            alt={user.displayName || "User Avatar"}
            priority
            sizes="100%"
          />
        )}
        {!user?.photoURL && user?.displayName && (
          <span className="inline-flex items-center justify-center size-8 rounded-full bg-gray-500 text-xs font-semibold text-white leading-none">
            {user?.displayName?.split(" ").map((n: string) => n[0])}
          </span>
        )}
        {!user?.photoURL && !user?.displayName && (
          <span className="inline-flex items-center justify-center size-8 rounded-full bg-gray-500 hover:bg-gray-300 text-xs font-semibold text-white leading-none">
            <FiUser className=" text-light" size={20} />
          </span>
        )}
      </button>

      <div
        className="hs-dropdown-menu transition-[opacity,margin] duration hs-dropdown-open:opacity-100 opacity-0 hidden min-w-60 bg-white shadow-md rounded-lg p-2 dark:bg-neutral-900 dark:border dark:border-neutral-700"
        aria-labelledby="hs-dropdown-with-header"
      >
        <div className="py-3 px-5 -m-2 bg-gray-100 rounded-t-lg dark:bg-neutral-800">
          <p className="text-sm font-medium text-gray-800 dark:text-neutral-300">
            {user?.displayName}
            <span className="text-gray-500 text-xs block">{user?.email}</span>
          </p>
        </div>
        <div className="mt-2 py-2 first:pt-0 last:pb-0">
          {AvatarLinks.map((link, index) => (
            <Link
              key={index}
              href={link.path}
              className="flex items-center gap-x-3.5 py-2 px-3 rounded-lg text-sm text-gray-800 hover:bg-gray-100 focus:ring-2 focus:ring-blue-500 dark:text-neutral-400 dark:hover:bg-neutral-700 dark:hover:text-neutral-300"
            >
              {link.icon && <link.icon />}
              {link.title}
            </Link>
          ))}
          <button
            className="w-full py-3 px-4 inline-flex items-center gap-x-2 text-sm font-semibold rounded-lg border border-transparent text-dark hover:bg-danger-50 disabled:opacity-50 disabled:pointer-events-none"
            type="button"
            onClick={() => {
              signOut(auth);
            }}
          >
            <FiLogOut />
            Cerrar Sesión
          </button>
        </div>
      </div>
    </div>
  );
}
