import { db } from '@/lib/firebase/client';

import {
  collection,
  addDoc,
  doc,
  updateDoc,
  deleteDoc,
  getDoc,
  query,
  where,
  getDocs,
  serverTimestamp,
  Timestamp,
  CollectionReference,
  DocumentReference,
  onSnapshot,
  WhereFilterOp,
  orderBy
} from 'firebase/firestore';

interface BaseModel {
  id?: string;
  userId: string;
  createdAt?: Timestamp;
  updatedAt?: Timestamp;
}

interface ModelFilter {
  field: string;
  op: WhereFilterOp;
  value: any;
}

interface ModelSort {
  field: string;
  direction: "asc" | "desc";
}

class FirestoreModel<T extends BaseModel> {
  private collectionRef: CollectionReference;
  
  constructor(collectionName: string) {
    this.collectionRef = collection(db, collectionName);
  }
  
  async create(data: T): Promise<T> {
    const newData = {
      ...data,
      createdAt: serverTimestamp(),
      updatedAt: serverTimestamp(),
    };
    const docRef: DocumentReference = await addDoc(this.collectionRef, newData);
    return { ...newData, id: docRef.id } as T;
  }
  
  async update(id: string, data: Partial<T>): Promise<void> {
    const updatedData = {
      ...data,
      updatedAt: serverTimestamp(),
    };
    const docRef = doc(this.collectionRef, id);
    await updateDoc(docRef, updatedData);
  }
  
  async delete(id: string, subcollections:string[]=[]): Promise<void> {
    const docRef = doc(this.collectionRef, id);
    for (const subcollection of subcollections) {
      const subcollectionDocs = await getDocs(collection(docRef, subcollection));
      subcollectionDocs.forEach(async (subDoc) => {
        await deleteDoc(subDoc.ref);
      });
    }
    await deleteDoc(docRef);
  }
  
  getRef(id: string): DocumentReference {
    return doc(this.collectionRef, id);
  }
  
  
  
  async get(id: string): Promise<T | undefined> {
    const docRef = doc(this.collectionRef, id);
    const docSnap = await getDoc(docRef);
    return docSnap.exists() ? ({ id: docSnap.id, ...docSnap.data() } as T) : undefined;
  }
  
  async getWithRealtimeUpdates(id: string, callback: (data: T | null) => void): Promise<void> {
    const docRef = doc(this.collectionRef, id);
    
    onSnapshot(docRef, (docSnapshot) => {
      if (docSnapshot.exists()) {
        const data = { id: docSnapshot.id, ...docSnapshot.data() } as T;
        callback(data);
      } else {
        callback(null);
      }
    });
  }
  
  async list(conditions: Array<ModelFilter> = [], sortBy:Array<ModelSort> = []): Promise<T[]> {
    let q = query(this.collectionRef);
    conditions.forEach(condition => {
      q = query(q, where(condition.field, condition.op, condition.value));
    });
    sortBy.forEach(order => {
      q = query(q, orderBy(order.field, order.direction));
    });
    const querySnapshot = await getDocs(q);
    return querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() } as T));
  }
  
  async listWithRealtimeUpdates(conditions: Array<ModelFilter> = [], sortBy:Array<ModelSort>=[], callback: (data: T[]) => void): Promise<void> {
    let q = query(this.collectionRef);
    conditions.forEach(condition => {
      q = query(q, where(condition.field, condition.op, condition.value));
    });
    sortBy.forEach(order => {
      q = query(q, orderBy(order.field, order.direction));
    });
    
    onSnapshot(q, (querySnapshot) => {
      const data = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() } as T));
      callback(data);
    });
  }
  
  
}

export default FirestoreModel;
export type { BaseModel, ModelFilter, ModelSort };
