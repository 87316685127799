"use client";
import Link from "next/link";
import Avatar from "./Avatar";
import { headerButtons } from "@/config/Header";
import HeaderButtons from "@/components/HeaderButtons";
import { FiSearch } from "react-icons/fi";
import Logo from "./Logo";
import { useContext } from "react";
import { AuthContext } from "@/app/providers";

export default function Header() {
  const { user, userClaims } = useContext(AuthContext);
  return (
    <header className="sticky top-0 inset-x-0 flex flex-wrap sm:justify-start sm:flex-nowrap z-[48] w-full bg-light border-b text-sm py-2.5 sm:py-4 lg:ps-64 dark:bg-dark dark:border-neutral-700">
      <nav
        className="flex basis-full items-center w-full mx-auto px-4 sm:px-6"
        aria-label="Global"
      >
        <div className="me-5 lg:me-0 lg:hidden w-full">
          {/* <!-- Logo  --> */}
          <Link
            className="flex-none rounded-xl text-xl inline-block font-semibold focus:outline-none focus:opacity-80 w-full"
            href="/app"
            aria-label="Preline"
          >
            <Logo darkModeForce="system" />
          </Link>
          {/* <!-- End Logo --> */}
        </div>

        <div className="w-full flex items-center justify-end ms-auto sm:justify-end sm:gap-x-3 sm:order-3">
          <div className="hidden">
            <button
              type="button"
              className="w-[2.375rem] h-[2.375rem] inline-flex justify-center items-center gap-x-2 text-sm font-semibold rounded-full border border-transparent text-gray-800 hover:bg-gray-100 disabled:opacity-50 disabled:pointer-events-none dark:text-white dark:hover:bg-neutral-600"
            >
              <FiSearch />
            </button>
          </div>

          <div className="hidden ">
            <label htmlFor="icon" className="sr-only">
              Search
            </label>
            <div className="relative min-w-72 md:min-w-80">
              <div className="absolute inset-y-0 start-0 flex items-center pointer-events-none z-20 ps-4">
                <FiSearch />
              </div>
              <input
                type="text"
                id="icon"
                name="icon"
                className="py-2 px-4 ps-11 block w-full border rounded-lg text-sm focus:border-primary-500 focus:ring-primary-500 disabled:opacity-50 disabled:pointer-events-none dark:bg-dark dark:border-neutral-700 dark:text-neutral-400 dark:placeholder-neutral-500 dark:focus:ring-neutral-600"
                placeholder="Search"
              />
            </div>
          </div>

          <div className="flex flex-row items-center justify-end gap-2">
            {/* <DarkmodeToggle /> */}
            {headerButtons.map((buttonProps: any, index: number) => (
              <HeaderButtons key={index} buttonProps={buttonProps} />
            ))}
            <span className="bg-secondary-400 px-2 py-1 rounded-full">
              {userClaims?.accountType === "premium" ? "Premium" : "Free"}
            </span>
            <Avatar />
          </div>
        </div>
      </nav>
    </header>
  );
}
