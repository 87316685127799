import FirestoreModel from '@/models/_firestore';
import { BaseModel } from '@/models/_firestore';


interface FeedbackModel extends BaseModel {
    browser: string;
    url: string;
    referer: string;
    useragent: string;
    screen: string;
    viewport: string;
    language: string;
    colordepth: string;
    feedbackText: string;
    userName: string;
    userId: string;
    email: string;
}

class Feedback extends FirestoreModel<FeedbackModel> {
  constructor() {
    super('feedback');
  }
}

const feedbackInstance = new Feedback();
export default feedbackInstance;