type NotificationBannerProps = {
  color:
    | "primary"
    | "secondary"
    | "accent"
    | "success"
    | "warning"
    | "info"
    | "danger";
  message: string;
};

const colorClasses: { [key in NotificationBannerProps["color"]]: string } = {
  primary:
    "bg-primary-300 text-primary-700 dark:bg-primary-700 dark:text-primary-300",
  secondary:
    "bg-secondary-300 text-secondary-700 dark:bg-secondary-700 dark:text-secondary-300",
  accent:
    "bg-accent-300 text-accent-700 dark:bg-accent-700 dark:text-accent-300",
  success:
    "bg-success-300 text-success-700 dark:bg-success-700 dark:text-success-300",
  warning:
    "bg-warning-300 text-warning-700 dark:bg-warning-700 dark:text-warning-300",
  info: "bg-info-300 text-info-700 dark:bg-info-700 dark:text-info-300",
  danger:
    "bg-danger-300 text-danger-700 dark:bg-danger-700 dark:text-danger-300",
};

const NotificationBanner: React.FC<NotificationBannerProps> = ({
  color,
  message,
}) => {
  return (
    <div className={`${colorClasses[color]} text-center py-1 text-sm`}>
      <p>{message}</p>
    </div>
  );
};

export default NotificationBanner;
