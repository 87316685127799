"use client";
import "@/app/globals.css";
import Header from "@/components/Header";
import Breadcrumb from "@/components/Breadcrumb";
import ApplicationSidebar from "@/components/ApplicationSidebar";
import { Suspense, useContext, useEffect, useState } from "react";
import Loading from "@/components/Loading";
import { useRouter } from "next/navigation";
import { AuthContext } from "@/app/providers";
import ImportantNotification from "@/components/ImportantNotification";
import PrelineScript from "@/components/PrelineScript";
import { FeedbackModalPopup } from "@/components/FeedbackModal";
export default function AppLayout({
  children,
}: Readonly<{
  children: React.ReactNode;
}>) {
  const router = useRouter();
  const { user, userClaims, loading, error } = useContext(AuthContext);
  const [isAuthChecked, setIsAuthChecked] = useState(false);

  useEffect(() => {
    if (user !== undefined) {
      setIsAuthChecked(true);
      if (!user) {
        return router.push("/auth/login");
      }
    }
  }, [user]);

  if (!isAuthChecked) {
    return <Loading />;
  }

  return (
    <>
      {user && !user.emailVerified && (
        <ImportantNotification
          color={"warning"}
          message={
            "Por favor... verifica tu correo electrónico para poder publicar un menú. "
          }
        />
      )}
      <Header />
      <Breadcrumb />
      <ApplicationSidebar />

      <main id="content" className="w-full lg:ps-64 ">
        <div className="p-4 sm:p-6 space-y-4 sm:space-y-6">
          <Suspense fallback={<Loading />}>{children}</Suspense>
        </div>
        <FeedbackModalPopup />
      </main>
      <PrelineScript />
    </>
  );
}
