import React from "react";

export default function Logo({
  darkModeForce = "system",
  legend = "right",
}: {
  darkModeForce?: "dark" | "light" | "system";
  legend?: "right" | "bottom" | "none";
}) {
  let fillClass: string;
  let textClass: string;
  switch (darkModeForce) {
    case "dark":
      fillClass = "fill-light";
      textClass = "text-light";
      break;
    case "light":
      fillClass = "fill-dark";
      textClass = "text-dark";
      break;
    case "system":
    default:
      fillClass = "fill-dark dark:fill-light";
      textClass = "text-dark dark:text-light";
      break;
  }

  return (
    <div className="flex flex-row items-center justify-start gap-2">
      <div className="h-[40px] w-[40px]">
        <svg
          width="100%"
          height="100%"
          version="1.1"
          viewBox="0 0 48.625 48.625"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g>
            <g className={`${fillClass}`} fillRule="evenodd">
              <path
                transform="scale(.26458)"
                d="m90.482 15.533c-1.5813-0.42636-3.3388-1.5047-4.0285-2.4716-0.45099-0.6323-0.57864-1.1428-0.57795-2.3115 0.001132-1.9186 0.56603-3.0847 2.3787-4.9098 3.2093-3.2316 7.8528-4.3023 11.495-2.6509 3.9077 1.7716 5.3076 4.2575 4.0088 7.1187-0.952 2.0972-3.8635 4.2494-6.9916 5.1682-1.5932 0.46796-4.6575 0.49576-6.2848 0.057z"
              />
              <path
                transform="scale(.26458)"
                d="m43.625 98.388c-3.7933-0.44459-5.879-1.4177-6.6425-3.0992-0.74347-1.6374-0.64332-4.978 0.28594-9.5385 0.42027-2.0625 1.0969-5.7188 1.5037-8.125 3.1305-18.519 8.4219-30.344 17.51-39.132 6.9619-6.7324 14.548-10.758 24.053-12.765 3.7498-0.79162 11.697-1.1011 15.926-0.62025 23.583 2.6815 45.475 19.688 52.819 41.031 1.9139 5.5625 2.7645 11.125 2.624 17.161-0.0519 2.227-0.15933 4.1141-0.23885 4.1937-0.13271 0.13271-52.597-3.9718-54.367-4.2534l-0.77663-0.12352-0.14278-6.8079c-0.13902-6.6283-0.63063-12.732-1.9376-24.058-0.28561-2.475-0.61689-7.1438-0.73618-10.375-0.28962-7.8452-0.78364-9.4641-3.2322-10.592-1.6826-0.7749-5.5405-0.70805-8.773 0.15201-4.9698 1.3223-11.006 3.9464-14.75 6.4127-6.8979 4.5435-12.06 11.367-15.623 20.652-2.4439 6.369-4.0546 13.34-6.0019 25.976-0.48531 3.1491-0.8527 5.754-0.81643 5.7886 0.03628 0.03459 2.991 0.1864 6.566 0.33738 7.8425 0.33118 23.723 0.34561 38 0.03453 18.872-0.41119 54.543-0.40908 57.5 0.0034 3.8305 0.53425 6.4149 1.8771 7.3566 3.8224 0.62423 1.2895 0.74523 4.163 0.17529 4.163-0.49027 0-14.731-1.1288-25.157-1.9941-16.485-1.3682-20.909-1.6006-30.75-1.6153-9.0264-0.0135-9.6306 0.01602-16.25 0.79348-3.7812 0.44412-9.0125 0.92358-11.625 1.0654s-7.45 0.53396-10.75 0.8713c-6.7347 0.68844-13.129 0.94905-15.75 0.64188z"
              />
              <path
                transform="scale(.26458)"
                d="m55.317 81.14c-0.58182-0.094818-1.1115-0.22609-1.1772-0.29171-0.16931-0.16931 1.0074-9.3136 1.6212-12.598 2.3729-12.699 6.4511-20.503 13.126-25.117 1.7759-1.2277 5.2479-3.0081 5.8662-3.0081 0.15721 0-0.18304 0.53438-0.7561 1.1875-8.6437 9.8512-11.46 16.327-15.004 34.5l-1.0847 5.5625-0.76674-0.03129c-0.42171-0.0172-1.2428-0.10887-1.8246-0.20369z"
              />
            </g>

            <path
              transform="scale(.26458)"
              d="m94.792 101.75c-2.1691-0.67687-2.5656-1.6553-1.2172-3.0036 1.0421-1.0421 2.61-1.5432 3.8469-1.2296 1.2752 0.32339 1.8973 1.274 2.0295 3.1012l0.10874 1.5034-1.8425-0.0167c-1.0134-9e-3 -2.3298-0.16878-2.9253-0.35462z"
              className={`${fillClass}`}
              fillRule="evenodd"
            />
            <path
              transform="scale(.26458)"
              d="m39.638 119.37c-4.5722-0.52287-7.9055-1.5505-10.421-3.2124-1.0133-0.66958-3.4174-2.6376-5.3424-4.3732s-3.6125-3.1584-3.75-3.1616c-0.51634-0.012 2.0772-1.0524 4.0588-1.6282 4.199-1.2202 7.0398-1.4919 15.441-1.4773 5.4325 9e-3 9.4694 0.15886 13.5 0.4996 5.443 0.46016 8.3525 0.48611 54.5 0.48611 47.442 0 57.075 0.12809 62.839 0.83564l1.3389 0.16436-1.1081 1.5c-1.4249 1.9287-4.7323 5.0152-6.5186 6.0832-3.224 1.9275-7.3777 3.1244-12.181 3.5102-4.0656 0.32655-43.217 0.34608-56.495 0.0282-19.774-0.47344-28.459-0.42105-36 0.21718-7.3763 0.62427-16.825 0.87562-19.862 0.52834z"
              className={`${fillClass}`}
              fillRule="evenodd"
            />
          </g>
          <g
            transform="translate(-88.856 -127.39)"
            className={`${fillClass}`}
            fillRule="evenodd"
          >
            <path
              transform="matrix(.26458 0 0 .26458 88.856 127.39)"
              d="m94.5 160.72c-4.9923-0.66905-8.3937-1.7974-10.216-3.3891-1.4867-1.2984-3.1176-3.4826-5.2645-7.0507-2.7111-4.5057-3.9538-6.1067-6.3642-8.1994-5.2432-4.5523-12.739-8.9807-22.593-13.348-1.8219-0.80744-3.3125-1.5409-3.3125-1.6299 0-0.2374 1.8862-0.91059 3.75-1.3384 8.1552-1.8718 20.49 0.81746 33.138 7.2247 2.2072 1.1181 4.9634 2.6924 6.125 3.4984 9.6223 6.677 11.105 7.4864 13.737 7.4983 3.1863 0.0144 5.503-1.881 10.68-8.7378 3.7135-4.9182 6.1169-7.3708 8.2536-8.4227 0.86673-0.42669 1.6289-0.72274 1.6938-0.65789 0.0649 0.0648-2.3077 3.411-5.2724 7.436-2.9646 4.0249-5.3379 7.3705-5.2738 7.4345 0.32332 0.32332 3.5579-0.57266 5.5434-1.5355 2.5532-1.2382 4.0706-2.4382 8.9121-7.0482 1.8766-1.7869 4.0694-3.6852 4.8729-4.2186 3.2532-2.1593 6.3877-2.7916 9.931-2.0033l1.9147 0.42598-0.81533 1.1669c-1.4076 2.0145-6.8856 7.306-11.565 11.171-5.6363 4.6555-8.4776 7.495-12.245 12.238-4.4008 5.5396-6.707 7.6971-9.3211 8.7202-2.9119 1.1396-10.764 1.508-16.308 0.76501z"
            />
            <path
              transform="matrix(.26458 0 0 .26458 88.856 127.39)"
              d="m71.641 180.23c0.06967-0.67516 0.34331-4.42 0.60809-8.3219 0.26478-3.9019 0.5256-7.1385 0.57961-7.1925 0.10194-0.10194 44.954 2.359 45.063 2.4725 0.0341 0.0354-0.11765 3.1124-0.3372 6.8379l-0.39919 6.7736-9.7652 0.16332c-5.3708 0.0898-15.64 0.23787-22.821 0.32897l-13.055 0.16564z"
            />
          </g>
        </svg>
      </div>
      {legend !== "none" && (
        <h2 className={`text-sm mt-1  text-center ${textClass} `}>
          WaiterMate
        </h2>
      )}
    </div>
  );
}
