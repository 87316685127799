
import { FiActivity, FiBell, FiUser, FiSettings, FiLogOut} from "react-icons/fi";
export const AvatarLinks = [
    {
        title: "Perfil",
        path: "/app/profile",
        icon: FiUser,
    },
    {
        title: "Configuración",
        path: "/app/settings",
        icon: FiSettings,
    },
    
    
];

export const headerButtons = [
    // {
    //     title: "Notifications",
    //     icon: FiBell
    // },
    // {
    //     title:"Activity",
    //     icon: FiActivity
    // }
];