"use client";
import { usePathname } from "next/navigation";
import Link from "next/link";
import Logo from "./Logo";
import {
  applicationSidebarLinks,
  applicationSidebarLinksAdmin,
  IApplicationSidebarLink,
} from "@/config/ApplicationSidebar";
import { FiChevronDown, FiChevronUp } from "react-icons/fi";
import { FeedbackModalButton } from "./FeedbackModal";
import { useContext, useEffect } from "react";
import { AuthContext } from "@/app/providers";

function LinkItem({
  props,
  isActive = false,
  userClaims,
  pathname,
}: {
  props: IApplicationSidebarLink;
  isActive?: boolean;
  userClaims?: any;
  pathname?: string;
}) {
  if (props.children) {
    return (
      <li className="hs-accordion" id={`${props.title}-accordion`}>
        <button
          type="button"
          className="hs-accordion-toggle w-full text-start flex items-center gap-x-3.5 py-2 px-2.5 hs-accordion-active:text-neutral-600 hs-accordion-active:hover:bg-transparent text-sm text-neutral-700 rounded-lg hover:bg-secondary-100 dark:bg-dark dark:hover:bg-neutral-700 dark:text-white dark:hover:text-neutral-300 dark:hs-accordion-active:text-white"
        >
          {props.icon && <props.icon />}
          {props.title}
          {props.badge && userClaims?.accountType !== "premium" && (
            <span className="inline-flex items-center gap-x-1.5 py-1.5 px-3 rounded-full text-[10px] font-medium bg-primary-100 text-primary-800 dark:bg-primary-800/30 dark:text-primary-500">
              {props.badge}
            </span>
          )}
          <FiChevronUp className="hs-accordion-active:block ms-auto hidden size-4" />
          <FiChevronDown className="hs-accordion-active:hidden ms-auto block size-4" />
        </button>

        <div
          id="users-accordion-child"
          className="hs-accordion-content w-full overflow-hidden transition-[height] duration-300 hidden"
        >
          <ul
            className="hs-accordion-group ps-3 pt-2"
            data-hs-accordion-always-open=""
          >
            {props.children &&
              props.children.map((link) => (
                <LinkItem
                  key={link.id}
                  props={link}
                  isActive={link.path === pathname ? true : false}
                />
              ))}
          </ul>
        </div>
      </li>
    );
  } else {
    return (
      <li>
        <Link
          className={`${
            isActive
              ? "bg-primary-500 dark:bg-dark text-dark dark:text-light "
              : ""
          } flex items-center gap-x-3.5 py-2 px-2.5 text-sm text-dark rounded-lg hover:bg-secondary-500 hover:border-1 hover:border-dark dark:hover:bg-dark dark:text-light`}
          href={props.path || "#"}
        >
          {props.icon && <props.icon />}
          {props.title}
          {props.badge && userClaims?.accountType !== "premium" && (
            <span className="inline-flex items-center gap-x-1.5 py-0.5 px-2 rounded-full text-[8px] font-medium bg-secondary-100 text-secondary-800 dark:bg-secondary-800/30 dark:text-secondary-500">
              {props.badge}
            </span>
          )}
        </Link>
      </li>
    );
  }
}

export default function ApplicationSidebar() {
  const pathname = usePathname();
  const { user, userClaims } = useContext(AuthContext);

  return (
    <div
      id="application-sidebar"
      className="hs-overlay [--auto-close:xs] hs-overlay-open:translate-x-0 -translate-x-full transition-all duration-300 transform w-[260px] fixed inset-y-0 start-0 z-[60] bg-light border-e border-gray-200 lg:block lg:translate-x-0 lg:end-auto lg:bottom-0 dark:bg-dark dark:border-neutral-600 hidden"
    >
      <div className="px-8 pt-4">
        {/* <!-- Logo --> */}
        <Link
          className="flex-none rounded-xl text-xl inline-block font-semibold focus:outline-none focus:opacity-80"
          href="/app"
          aria-label="Preline"
        >
          <Logo darkModeForce="system" />
        </Link>
        {/* <!-- End Logo --> */}
      </div>

      <nav
        className="hs-accordion-group p-6 w-full flex flex-col flex-wrap justify-between h-[70vh] overflow-y-auto "
        data-hs-accordion-always-open=""
      >
        <ul className="space-y-1.5">
          {applicationSidebarLinks.map((link) => (
            <LinkItem
              key={link?.id}
              props={link}
              isActive={link?.path === pathname ? true : false}
              userClaims={userClaims}
              pathname={pathname}
            />
          ))}
          {userClaims?.admin &&
            applicationSidebarLinksAdmin.map((link) => (
              <LinkItem
                key={link?.id}
                props={link}
                isActive={link?.path === pathname ? true : false}
                userClaims={userClaims}
                pathname={pathname}
              />
            ))}
        </ul>
        <FeedbackModalButton />
      </nav>
    </div>
  );
}
